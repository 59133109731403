import React from 'react'

const InterviewIcon = () => {

    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.16675 0.5C3.85275 0.5 1.16675 2.739 1.16675 5.5C1.16675 6.521 1.53575 7.47 2.16675 8.262V12L4.73975 10.07C5.48175 10.345 6.30175 10.5 7.16675 10.5C10.4807 10.5 13.1667 8.261 13.1667 5.5C13.1667 2.739 10.4807 0.5 7.16675 0.5Z" stroke="#CD8A11" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.88574 12.504C6.97474 13.427 8.49074 14 10.1667 14C10.9497 14 11.6957 13.871 12.3817 13.644L15.1667 15.5V11.762C15.7977 10.97 16.1667 10.022 16.1667 9C16.1667 8.091 15.8757 7.239 15.3677 6.505" stroke="#CD8A11" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default InterviewIcon