import React from 'react'
import LoopItem from '../components/Archive/LoopItem'

const Loop = ({stickyItem = false, items}) => {

    console.log(stickyItem)
    
    return (
        <div className="container mx-auto grid px-4 md:px-0 lg:px-24 md:grid-cols-2 xl:px-0 xl:grid-cols-4 gap-8">
            {stickyItem &&
                <LoopItem
                    key={8104}
                    imageRest={stickyItem[0]?.better_featured_image?.media_details?.sizes?.archive_large?.source_url}
                    image={``}
                    title={stickyItem[0]?.title?.rendered}
                    uri={`/${stickyItem[0]?.slug}`}
                    type={`Post`}
                    excerpt={stickyItem[0]?.excerpt?.rendered}
                    date={``}
                />
            }
            {items.map( (item, i ) => {
                
                const contentType = item.nodeType ? item.nodeType : item.contentTypeName;
                let   passDate    = item?.sermonMeta?.displayDate ? item?.sermonMeta?.displayDate : false;
                
                if ( ! passDate ) {
                    passDate = item?.resourceMeta?.displayDate ? item?.resourceMeta?.displayDate : item.date;
                }

                return (
                    <LoopItem
                        key={i}
                        imageRest={item?.imageRest}
                        image={item?.featuredImage?.node?.localFile}
                        title={item.title}
                        uri={item.uri}
                        type={contentType}
                        excerpt={item.excerpt}
                        date={passDate}
                    />
                )
            })}
        </div>
    )
}

export default Loop