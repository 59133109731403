import React from 'react'
import ArticleIcon from '../svgs/ArticleIcon';
import BookIcon from '../svgs/BookIcon';
import InterviewIcon from '../svgs/InterviewIcon';
import LectureIcon from '../svgs/LectureIcon';
import PodcastIcon from '../svgs/PodcastIcon';
import SeminarIcon from '../svgs/SeminarIcon';
import SermonIcon from '../svgs/SermonIcon';

const LoopItemType = ( { type } ) => {

    switch (type) {
        case 'Book' :
            return (
                <div className="flex items-center mb-4">
                    <BookIcon />
                    <span className="mx-2">Book</span>
                </div>
            )
        case 'Conference' :
            return (
                <div className="flex items-center mb-4">
                    <SeminarIcon />
                    <span className="mx-2">Conference</span>
                </div>
            )
        case 'Discussion' :
            return (
                <div className="flex items-center mb-4">
                    <InterviewIcon />
                    <span className="mx-2">Discussion</span>
                </div>
            )
        case 'Event' :
            return (
                <div className="flex items-center mb-4">
                    <SeminarIcon />
                    <span className="mx-2">Event</span>
                </div>
            )
        case 'Lecture' :
            return (
                <div className="flex items-center mb-4">
                    <LectureIcon />
                    <span className="mx-2">Lecture</span>
                </div>
            )
        case 'PodcastEpisode' :
            return (
                <div className="flex items-center mb-4">
                    <PodcastIcon />
                    <span className="mx-2">Podcast Episode</span>
                </div>
            )
        case 'Post' :
            return (
                <div className="flex items-center mb-4">
                    <ArticleIcon />
                    <span className="mx-2">Article</span>
                </div>
            )
        case 'Sermon' :
            return (
                <div className="flex items-center mb-4">
                    <SermonIcon />
                    <span className="mx-2">Sermon</span>
                </div>
            )
        case 'SermonClip' :
            return (
                <div className="flex items-center mb-4">
                    <SermonIcon />
                    <span className="mx-2">Sermon Clip</span>
                </div>
            )
        default :
            return null
    }
}

export default LoopItemType