import React from 'react'

const LectureIcon = () => {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 5.5V15.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 3.5C3.82843 3.5 4.5 2.82843 4.5 2C4.5 1.17157 3.82843 0.5 3 0.5C2.17157 0.5 1.5 1.17157 1.5 2C1.5 2.82843 2.17157 3.5 3 3.5Z" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.5 5.5H1.5C1.23478 5.5 0.98043 5.60536 0.792893 5.79289C0.605357 5.98043 0.5 6.23478 0.5 6.5V15.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 2.5H15.5V11.5H7.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.5 11.5L12.5 15.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.5 0.5V2.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default LectureIcon