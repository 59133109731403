import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Fallback = ( { type } ) => {

    switch (type) {
        case 'Book' :
            return <StaticImage src='../../assets/images/fallbacks/Book.jpg' className="rounded-t-3xl" alt="" />;
        case 'Conference' :
            return <StaticImage src='../../assets/images/fallbacks/Seminar.jpg' className="rounded-t-3xl" alt="" />;
        case 'Discussion' :
            return <StaticImage src='../../assets/images/fallbacks/Interview.jpg' className="rounded-t-3xl" alt="" />;
        case 'Event' :
            return <StaticImage src='../../assets/images/fallbacks/Seminar.jpg' className="rounded-t-3xl" alt="" />;
        case 'Lecture' :
            return <StaticImage src='../../assets/images/fallbacks/Lecture.jpg' className="rounded-t-3xl" alt="" />;
        case 'PodcastEpisode' :
            return <StaticImage src='../../assets/images/fallbacks/PodcastEpisode.jpg' className="rounded-t-3xl" alt="" />;
        case 'Post' :
            return <StaticImage src='../../assets/images/fallbacks/Post.jpg' className="rounded-t-3xl" alt="" />;
        case 'Sermon' :
            return <StaticImage src='../../assets/images/fallbacks/Sermon.jpg' className="rounded-t-3xl" alt="" />;
        case 'SermonClip' :
            return <StaticImage src='../../assets/images/fallbacks/SermonClip.jpg' className="rounded-t-3xl" alt="" />;
        default :
            return null
    }
}

export default Fallback