import React from 'react'

const ArticleIcon = () => {

    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.83337 15.5C1.72837 15.5 0.833374 14.605 0.833374 13.5V8.5H4.83337V13.5C4.83337 14.605 3.93837 15.5 2.83337 15.5Z" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.83337 8.5V0.5H15.8334V13.5C15.8334 14.605 14.9384 15.5 13.8334 15.5H2.83337" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.83337 3.5H12.8334" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.83337 6.5H12.8334" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.83337 9.5H12.8334" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.83337 12.5H9.83337" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ArticleIcon