import React from 'react'

const BookIcon = () => {

    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 2.5C8 1.395 9.679 0.5 11.75 0.5C13.821 0.5 15.5 1.395 15.5 2.5V13.5C15.5 12.395 13.821 11.5 11.75 11.5C9.679 11.5 8 12.395 8 13.5M8 2.5C8 1.395 6.321 0.5 4.25 0.5C2.179 0.5 0.5 1.395 0.5 2.5V13.5C0.5 12.395 2.179 11.5 4.25 11.5C6.321 11.5 8 12.395 8 13.5M8 2.5V13.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default BookIcon