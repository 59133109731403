import React from 'react'
import { Link } from 'gatsby'
import ArrowIcon from './svgs/ArrowIcon';

const SectionHeader = ( {label, link, linkLabel} ) => {

    return (
        <div className="text-center lg:flex lg:text-left lg:items-center mb-4 lg:w-full">
            <h2 className="text-sm font-sans px-6 xl:px-0 font-semibold tracking-widest uppercase mb-2 mt-0 flex-1">{label}</h2>

            { link && 
                 <Link
                    to={link}
                    className="flex items-center justify-center gap-2 uppercase text-sm text-green-900 mb-2"
                >
                    {linkLabel}
                    <ArrowIcon />
                </Link>
            }
        </div>
    )
}

export default SectionHeader;