import React from 'react'
import { Link } from 'gatsby'
import Fallback from './Fallback'
import LoopItemType from './LoopItemType'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

const LoopItem = ( { image, imageRest, title, uri, type, excerpt, date } ) => {

    const itemImage     = image ? getImage(image) : false;
    const formatDate    = new Date(date);
    const dateDisplay   = date ? new Intl.DateTimeFormat( 'en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(formatDate) : false;
    
    return (
        <Link to={uri} className="loop-item border border-gray-300 rounded-3xl text-gray-900 hover:text-gray-900 bg-white">
            
            { type === 'Book' ?
                <span className="relative flex items-center justify-center">
                    { imageRest &&
                        <img className="absolute z-20" src={imageRest} alt={title} width="100" />
                    }
                    <Fallback type={type} />
                </span>
                :
                <span>
                    { imageRest && 
                        <div className="h-[187px] xl:h-[165px] 2xl:h-[187px] overflow-hidden">
                            <img className="rounded-t-3xl object-cover h-full w-full" src={imageRest} alt={title} width="767" height="400" />
                        </div>
                    }
                    { itemImage &&
                        <GatsbyImage image={itemImage} className="rounded-t-3xl" width="767" />
                    }
                    { !imageRest && !itemImage &&
                        <Fallback type={type} />
                    }
                </span>
            }
            
            <div className="p-8">
                <LoopItemType
                    type={type}
                />
                <h3 className="mb-4 mt-0" dangerouslySetInnerHTML={{__html: title } } />
                <p dangerouslySetInnerHTML={ {__html: excerpt } } />
                <div className="text-xs uppercase tracking-wide font-light">{dateDisplay}</div>
            </div>
        </Link>
    )
}

export default LoopItem