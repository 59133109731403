import React from 'react'

const SeminarIcon = () => {

    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1649_1887)">
            <path d="M14.8334 0.5H1.83337V7.5H14.8334V0.5Z" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.83337 11.5V9.5H3.83337V11.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.8334 11.5V9.5H9.83337V11.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.83337 15.5V13.5H0.833374V15.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.8334 15.5V13.5H12.8334V15.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.83337 15.5V13.5H6.83337V15.5" stroke="#CD8A11" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1649_1887">
            <rect width="16" height="16" fill="white" transform="translate(0.333374)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default SeminarIcon