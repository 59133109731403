import React from 'react'

const SermonIcon = () => {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 3.5C7.60218 3.5 7.22064 3.34196 6.93934 3.06066C6.65804 2.77936 6.5 2.39782 6.5 2C6.5 1.60218 6.65804 1.22064 6.93934 0.93934C7.22064 0.658035 7.60218 0.5 8 0.5V0.5C8.39782 0.5 8.77936 0.658035 9.06066 0.93934C9.34196 1.22064 9.5 1.60218 9.5 2C9.5 2.39782 9.34196 2.77936 9.06066 3.06066C8.77936 3.34196 8.39782 3.5 8 3.5V3.5Z" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.5 8.5V6.5C5.5 6.23478 5.60536 5.98043 5.79289 5.79289C5.98043 5.60536 6.23478 5.5 6.5 5.5H9.5C9.76522 5.5 10.0196 5.60536 10.2071 5.79289C10.3946 5.98043 10.5 6.23478 10.5 6.5V8.5" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5 10.5H3.5" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.5 10.5H4.5V15.5H11.5V10.5Z" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default SermonIcon